import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Malopolska = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Małopolska",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #22967c, #30af93, #2ABA9A, #30af93, #22967c )",
        icons: "#00AEEB",
        navClass: "malopolska",
        ogImage: require("../../../assets/img/portfolio/malopolska_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/malopolska/",
      }}
    >
      <PortfolioHeader name="malopolska" height="82" />
      <section className="container-fluid malopolska_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Małopolska</h1>
              <ul>
                <li>Photo session</li>
                <li>Outdoor campaign</li>
                <li>Social media campaign</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                For the Marshal's Office of the Małopolska Region, we carried
                out a campaign promoting a scholarship program for the most
                talented students in Małopolska voivodeship.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid malopolska_section_3">
        <div className="row no-gutters align-items-center row_1">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/malopolska_main_1.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Billboards</h3>
              <p>
                For the purposes of the campaign, we organized a photo session
                in which the beneficiaries of the Regional Scholarship Program
                (organized by the Marshal's Office) took part. Showing the truly
                talented students from Małopolska was one of the main
                assumptions of our activities, thanks to which the students
                appeared on all promotional materials used in the campaign later
                on.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid malopolska_section_4">
        <div className="row row_1">
          <div className="col-md-5 text-col">
            <div className="inner">
              <h3>Citylights</h3>
              <p>
                The creations prepared by us also illuminated the public space
                with numerous citylights, where people could see young
                Małopolska residents distinguished for their achievements in
                science.
              </p>
            </div>
          </div>
          <div className="col-md-5 offset-md-2 img-col">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/malopolska_main_2.jpg")}
              alt=""
            />
            <img
              className="img-fluid img-absolute"
              src={require("../../../assets/img/portfolio/malopolska_main_3.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="malopolska_section_5">
        <div className="row row_1">
          <div className="col-md-5 img-col">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/malopolska_main_4.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-5 col-md-7 offset-lg-1 text-col">
            <div className="inner">
              <h3>Testimonials</h3>
              <p>
                The campaign ended with success, and we received recommendations
                from the Marshal's Office of the Małopolska Region.
              </p>
            </div>
          </div>
        </div>
        <div className="baner">
          <img
            className="img-fluid baner-img"
            src={require("../../../assets/img/portfolio/malopolska_baner.jpg")}
            alt=""
          />
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Malopolska;
